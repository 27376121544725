var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"barsContainer",staticClass:"gl-scoring-bar-chart__wrapper",class:{ 'gl-scoring-bar-chart__two-line-view': _vm.twoLineView }},[(_vm.loading)?_c('VueSkeletonLoader',{attrs:{"animation":"wave","color":'#bec3d8',"height":20,"radius":'2',"type":"rect"}}):(_vm.dataTableResult.length)?_c('div',[_c('transition-group',{attrs:{"appear":"","name":"slide"}},_vm._l((_vm.dataTableResult),function(item,i){return _c('div',{key:i,staticClass:"risk-exposure__table"},[_c('div',{class:_vm.classesForRiskExposureRow(item),on:{"click":function($event){return _vm.typeEntityItemClick(item)}}},[_c('div',{staticClass:"risk-exposure__row-badge"},[_c('GlBadgeInfo',{attrs:{"entity-id":item.entityId,"text":_vm.formatLabelName(item),"value":_vm.getItemScore(item)}}),_c('div',{staticClass:"risk-exposure__row-info-values"},[(item.formattedAmount)?_c('div',{staticClass:"risk-exposure__row-info-values-amount"},[_vm._v(" "+_vm._s(item.formattedAmount)+" ")]):_vm._e(),_c('div',{staticClass:"risk-exposure__row-info-values-price",class:{
                  'risk-exposure__row-info-values-amount':
                    !item.formattedAmount,
                }},[_vm._v(" "+_vm._s(item.amountCur ? _vm.formatByPrice(item.amountCur) : item.price ? _vm.formatByPrice(item.price) : '')+" ")])])],1),_c('div',{staticClass:"risk-exposure__row-info"},[_c('div',{staticClass:"risk-exposure__row-info-percents"},[(item.deepDepthShare)?_c('div',{directives:[{name:"popover",rawName:"v-popover:tooltip.top",value:('Indirect'),expression:"'Indirect'",arg:"tooltip",modifiers:{"top":true}}],staticClass:"risk-exposure__row-info-percents-item risk-exposure__row-info-percents-item--indirect",style:(`width: ${_vm.calculateWidth(
                  item,
                  item.deepDepthShare * 100,
                  _vm.percentsInPixels,
                )}`),on:{"click":function($event){return _vm.typeEntityItemClick(item)}}},[_vm._v(" "+_vm._s(_vm.formatShare(_vm.roundShare(item.deepDepthShare)))+" ")]):_vm._e(),(item.generalShare)?_c('div',{directives:[{name:"popover",rawName:"v-popover:tooltip.top",value:('Direct'),expression:"'Direct'",arg:"tooltip",modifiers:{"top":true}}],staticClass:"risk-exposure__row-info-percents-item risk-exposure__row-info-percents-item--direct",style:(`width: ${_vm.calculateWidth(
                  item,
                  item.generalShare * 100,
                  _vm.percentsInPixels,
                )}`),on:{"click":function($event){return _vm.typeEntityItemClick(item)}}},[_vm._v(" "+_vm._s(_vm.formatShare(_vm.roundShare(item.generalShare)))+" ")]):_vm._e()])]),_c('div',{staticClass:"risk-exposure__row-cntrl"},[_c('GlArrow',{staticClass:"risk-exposure__row-cntrl-arrow"}),_c('GlClose',{staticClass:"risk-exposure__row-cntrl-close"})],1)])])}),0)],1):_c('div',{staticClass:"risk-exposure__empty"},[_c('div',[_vm._v("There is no data yet.")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }