<template>
  <div
    class="gl-circle-chart__wrapper"
    :class="{ 'gl-circle-chart__wrapper--loading': loading }"
  >
    <VueSkeletonLoader
      v-if="loading"
      animation="pulse"
      class="gl-circle-chart__loader"
      type="circle"
    />
    <div
      v-else
      class="gl-circle-chart__info"
    >
      <div v-if="showRiskScore">
        <div class="gl-circle-chart__score">
          <div>
            {{ formatFunds(innerScore, false) }}
          </div>
        </div>
        <div class="gl-circle-chart__text">
          <div>
            {{ calculateRiskLevel(score) }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="gl-circle-chart__score-empty"
      >
        –
      </div>
    </div>

    <div class="gl-circle-chart-container">
      <svg
        height="80%"
        viewBox="0 0 220 220"
        width="80%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="110"
          cy="110"
          fill="none"
          r="92"
          stroke="#1149FF"
          stroke-width="10"
        />

        <circle
          ref="progressCircle"
          cx="110"
          cy="110"
          fill="none"
          r="98"
          stroke="#FF2A00"
          stroke-dasharray="628"
          :stroke-dashoffset="dashOffset"
          stroke-width="22"
          transform="rotate(-90 110 110)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
// components
import VueSkeletonLoader from 'skeleton-loader-vue'

// utils
import { calculateRiskLevel } from '@/utils/risk-formatter'
import { formatFunds } from '@/utils/report-data-formatter'

export default {
  components: {
    VueSkeletonLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    score: {
      type: [Number, String],
      default: '',
    },
    hasFundsData: {
      type: Boolean,
      default: true,
    },
    animationDuration: {
      type: [Number, String],
      default: 1000,
    },
  },
  data() {
    return {
      innerScore: null,
    }
  },
  computed: {
    dashOffset() {
      const circleCircumference = 2 * Math.PI * 100
      return circleCircumference * (1 - this.innerScore / 102)
    },
    showRiskScore() {
      return Boolean(this.hasFundsData && parseFloat(this.innerScore) >= 0)
    },
  },
  watch: {
    score: {
      handler(score) {
        setTimeout(() => {
          this.innerScore = score
          this.animateScore(score)
        }, 300)
      },
      immediate: true,
    },
  },
  methods: {
    formatFunds,
    calculateRiskLevel,
    animateScore(targetScore) {
      const start = performance.now()
      const duration = this.animationDuration
      const animate = currentTime => {
        const elapsedTime = currentTime - start
        const progress = Math.min(elapsedTime / duration, 1)
        this.innerScore = Math.abs(progress * targetScore)

        if (progress < 1) {
          requestAnimationFrame(animate)
        }
      }

      requestAnimationFrame(animate)
    },
  },
}
</script>
<style>
.gl-circle-chart__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
  width: 280px;
  min-width: 100%;
  min-height: 100%;
  background: var(--white);
  border-radius: 280px;
  box-shadow: 0px 4px 12px -4px rgba(14, 11, 61, 0.2);
  outline: 20px solid #f5f5f550;
}
.gl-circle-chart__wrapper--loading {
  animation: pulse-circle 1.5s linear 0.5s infinite;
}
.gl-circle-chart-container {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gl-circle-chart__info {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.gl-circle-chart__score {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 52px;
  font-weight: 600;
  line-height: 62px;
  border-bottom: 1px solid var(--space-cadet);
}
.gl-circle-chart__score-empty {
  font-size: 60px;
  font-weight: 900;
}
.gl-circle-chart__text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  text-transform: uppercase;
}

.gl-circle-chart__loader {
  max-height: 12px;
  max-width: 36px;
  background-color: #1149ff !important;
}

@keyframes pulse-circle {
  0% {
    transform: scale(1);
    outline: 20px solid #f5f5f550;
  }
  50% {
    transform: scale(0.97);
    outline: 0px solid #f5f5f5;
  }
  100% {
    transform: scale(1);
    outline: 20px solid #f5f5f550;
  }
}
</style>
