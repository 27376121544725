<template>
  <div :class="classesForBadgeInfo">
    <div
      v-if="$slots.text"
      class="gl-badge-info__text"
    >
      <slot name="text" />
    </div>
    <div
      v-else
      class="gl-badge-info__text"
      :class="[{ link: entityId }]"
      @click.stop="clickOnText(entityId)"
    >
      {{ text }}
    </div>
    <div
      v-if="isValuePresent"
      class="gl-badge-info__value"
    >
      {{ calculateRiskLevel(value) }}
    </div>
  </div>
</template>

<script>
// Utils
import { calculateRiskLevel } from '@/utils/risk-formatter'
import { goToEntity } from '@/utils/go-to-route'

export default {
  name: 'GlBadgeInfo',
  props: {
    text: {
      type: String,
      default: 'Not Identified',
    },
    value: {
      type: [String, Number],
      default: 0,
    },
    entityId: {
      type: String,
      default: null,
    },
    chipsView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    isValuePresent() {
      return (
        this.value != null &&
        (typeof this.value !== 'string' || this.value.trim() !== '')
      )
    },
    classesForBadgeInfo() {
      let classes = [`gl-badge-info`]

      if (this.chipsView) {
        classes.push(`gl-badge-info__chips-view`)
      }
      classes.push(`gl-badge-info--${this.calculateRiskLevel(this.value)}`)

      return classes
    },
  },
  methods: {
    calculateRiskLevel,
    goToEntity,
    clickOnText(id) {
      if (!id) return
      this.goToEntity(id)
    },
  },
}
</script>

<style>
.gl-badge-info {
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  max-width: 100%;
}
.gl-badge-info__text {
  flex: 0 1 auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--Accent-Space-Cadet);
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  z-index: 2;
}
.gl-badge-info__value {
  flex: 0 0 auto;
  padding: 4px 8px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: var(--white);
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}
.gl-badge-info--low .gl-badge-info__value {
  background-color: var(--Accent-Green);
}
.gl-badge-info--medium .gl-badge-info__value {
  background-color: var(--color-tag-50);
  color: var(--black);
}
.gl-badge-info--high .gl-badge-info__value {
  background-color: var(--Accent-Scarled-Red);
}
.gl-badge-info--unknown .gl-badge-info__value {
  background: var(--stripped-gradient-unknown);
  color: var(--black);
}
.gl-badge-info--pending .gl-badge-info__value {
  background-color: var(--accent-reflex-blue);
}

.gl-badge-info__chips-view {
  flex-direction: row-reverse;
  padding: 3px;
  padding-right: 8px;
  background: var(--Accent-Coton-Grey);
  border-radius: 16px;
}
</style>
