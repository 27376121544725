<template>
  <div>
    <div
      v-if="title"
      class="profile-owner__title"
    >
      {{ title }}
    </div>
    <div
      v-if="ownerData.owner"
      class="profile-owner__name"
      :class="{ 'link-text': ownerData.entityId }"
      @click="goToEntity(ownerData.entityId)"
    >
      <GlRowWrapper
        ellipsis
        :icon-height="15"
        :icon-name="ownerData.entityId ? 'open-on-new-page' : ''"
        :icon-width="15"
        is-icon-link
        :value="ownerData.owner"
      />
    </div>
    <div
      v-if="ownerData.description"
      class="profile-owner__description"
    >
      <template
        v-if="ownerData.description && isValidURL(ownerData.description)"
      >
        <GlLink :url="ownerData.description">
          <GlRowWrapper
            :icon-height="11"
            icon-name="open-on-new-page"
            :icon-width="11"
            is-icon-link
          >
            <template #value>
              <div> Source description </div>
            </template>
          </GlRowWrapper>
        </GlLink>
      </template>
      <template v-else>
        {{ ownerData.description }}
      </template>
    </div>
  </div>
</template>

<script>
// Components
import GlRowWrapper from '@/components/text/gl-row-wrapper'
import GlLink from '@/components/gl-link'
// Utils
import { goToEntity } from '@/utils/go-to-route'
import { isValidURL } from '@/utils/text-formatter'

export default {
  name: 'ProfileOwner',
  components: { GlRowWrapper, GlLink },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    ownerData: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    goToEntity,
    isValidURL,
  },
}
</script>
<style>
.profile-owner__title {
  color: var(--Content-Tertiary);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.profile-owner__name {
  color: var(--Content-Primary);
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 4px;
  width: fit-content;
}
.profile-owner__description {
  color: var(--Content-Secondary);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  max-width: 640px;
  width: fit-content;
}
</style>
