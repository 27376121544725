<template>
  <div class="profile-labels__wrapper">
    <div
      v-if="title"
      class="profile-labels__title"
    >
      {{ title }}
    </div>
    <div class="profile-labels__content">
      <GlBadgeInfo
        v-for="item in labelsResult"
        :key="item._id || item.tagId"
        chips-view
        :value="item.score ?? -1"
      >
        <template #text>
          <template v-if="item.metadata">
            <template v-if="isValidURL(item.metadata)">
              <GlLink :url="item.metadata">
                <GlRowWrapper
                  ellipsis
                  :icon-height="14"
                  icon-name="open-on-new-page"
                  :icon-width="14"
                  is-icon-link
                >
                  <template #value>
                    <template>
                      {{ item.name }}
                    </template>
                  </template>
                </GlRowWrapper>
              </GlLink>
            </template>
            <template v-else-if="item.metadata">
              <GlRowWrapper
                v-popover:tooltip.top="item.metadata"
                class="pointer"
                ellipsis
                :icon-height="14"
                icon-name="info-new-gray"
                :icon-width="14"
              >
                <template #value>
                  <template>
                    {{ item.name }}
                  </template>
                </template>
              </GlRowWrapper>
            </template>
          </template>

          <div
            v-else
            class="ellipsis"
          >
            {{ item.name }}
          </div>
        </template>
      </GlBadgeInfo>
    </div>
  </div>
</template>

<script>
// Components
import GlRowWrapper from '@/components/text/gl-row-wrapper'
import GlLink from '@/components/gl-link'
import GlBadgeInfo from '@/components/gl-badge-info'
// Utils
import { isValidURL } from '@/utils/text-formatter'

export default {
  name: 'ProfileLabels',
  components: { GlBadgeInfo, GlRowWrapper, GlLink },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    ownerData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    labelsResult() {
      let labels = this.formatArray(
        [
          ...(this.ownerData?.meta || []),
          ...(this.ownerData?.tags || []),
          this.ownerData?.type || '',
          this.ownerData?.type || '',
        ].filter(e => e),
      )
      return labels
    },
  },
  methods: {
    isValidURL,
    formatArray(array) {
      return array
        .map(obj =>
          obj.tagId ? { _id: obj.tagId, ...obj } : obj,
        )
        .reduce((accumulator, current) => {
          if (!accumulator.some(item => item._id === current._id)) {
            accumulator.push(current)
          }
          return accumulator
        }, [])
        .sort((a, b) => {
          if (a.score == null) return 1
          if (b.score == null) return -1
          return b.score - a.score
        })
    },
  },
}
</script>
<style>
.profile-labels__wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.profile-labels__title {
  color: var(--Content-Tertiary);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.profile-labels__content {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.profile-labels__wrapper .gl-badge-info__text {
  text-transform: none;
}
.profile-labels__wrapper .gl-badge-info__text *::first-letter {
  text-transform: capitalize;
}

.profile-labels__wrapper .gl-link__wrapper {
  color: unset;
}
.profile-labels__wrapper .gl-link__wrapper:visited {
  color: unset;
}

@media (max-width: 767px) {
  .profile-labels__content {
    flex-direction: column;
  }
}
</style>
